import { createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ignoreSpoofedUserIdContextKey } from '../../../common/constants/apolloContextKeys'
import { xHasuraUserIdHeader } from '../../../common/constants/headers'
import { realizeAdminSpoofedUserSessionStorageKey } from '../../../common/constants/sessionStorage'
import {
  clientAdminGqlEndpoint,
  clientAdminRelayEndpoint,
  clientNonAdminStellateCacheableGqlHttpEndpoint,
  clientNonAdminRelayEndpoint,
} from '../../../common/constants/envDependentRoutes'
import { clientVersionHeaders } from '../common'

export const nonAdminHttpLink = createHttpLink({
  uri: clientNonAdminStellateCacheableGqlHttpEndpoint,
  credentials: 'include',
  headers: clientVersionHeaders,
})
export const nonAdminRelayLink = createHttpLink({
  uri: clientNonAdminRelayEndpoint,
  credentials: 'include',
  headers: clientVersionHeaders,
})

const addAdminHeadersLink = setContext(async (_operation, { headers, ...context }) => {
  const spoofedUserId =
    !context[ignoreSpoofedUserIdContextKey] &&
    typeof window !== 'undefined' &&
    sessionStorage.getItem(realizeAdminSpoofedUserSessionStorageKey)

  const adminHeaders = spoofedUserId && {
    [xHasuraUserIdHeader]: spoofedUserId,
  }

  return {
    headers: { ...adminHeaders, ...headers },
    ...context,
  }
})

const adminGraphqlEndpointLink = createHttpLink({
  uri: clientAdminGqlEndpoint,
  credentials: 'include',
  headers: clientVersionHeaders,
})

const adminRelayEndpointLink = createHttpLink({
  uri: clientAdminRelayEndpoint,
  credentials: 'include',
  headers: clientVersionHeaders,
})

export const adminGraphqlLink = addAdminHeadersLink.concat(adminGraphqlEndpointLink)
export const adminRelayLink = addAdminHeadersLink.concat(adminRelayEndpointLink)
